export const Schedule = (props) => {
  return (

    <div id='schedule'>
      <div className='container-fluid'>
        <div className='text-center'>
          <h2>Schedule</h2>
        </div>
        <div>
            {props.data ? props.data.reverse().map((s, i) => (
              <table className="table table-hover">
                <thead className="trhead">
                  <tr>
                    <th scope="col" className="col-md-4 col-sm-4 col-xs-4">{s.title}</th>
                    <th scope="col" className="col-md-5 col-sm-5 col-xs-4">{s.subtitle}</th>
                    <th scope="col" className="col-md-3 col-sm-3 col-xs-4">{s.location}</th>
                  </tr>
                </thead>
                <tbody>
                  {s.data ? s.data.map((d, i) => (
                    <tr>
                      <td className={s.className}>{d.timeslot}</td>
                      <td colSpan={1} className={s.className}>{d.band}</td>
                      <td><a href={s.ticket} target="_blank" rel="noreferrer"  className="btn btn-primary-outline" >{d.tickettitle}</a></td>
                    </tr>
                  ))
                    : "loading"}
                </tbody>
              </table>
            )) : "loading"}

        </div>
      </div>

    </div>


  );
};
