export const Contact = () => {
  return (
    <div id='footer'>
    <div className='container-fluid text-center'>
      <h5>
        For more details please contact info[at]patoghmunich.com
      </h5>
      <p>
        © Patogh e.V. 2023
      </p>

      {/* <div>
<a style={{"color": "#3b5998"}} href="#!" role="button"><i class="fab fa-facebook-f fa-lg"></i></a>

<a style={{"color": "#55acee"}} href="#!" role="button"><i class="fab fa-twitter fa-lg"></i></a>

<a style={{"color": "#dd4b39"}} href="#!" role="button"><i class="fab fa-google fa-lg"></i></a>

<a style={{"color": "#ac2bac"}} href="#!" role="button"><i class="fab fa-instagram fa-lg"></i></a>
      </div> */}
      
    </div>
    </div>
  )
}
