
export const Artists = (props) => {
  return (
    <div id={props.data.id} className="artists">
      <div className='container-fluid'>
        <div className='section-title text-center'>
          <h2>{props.data.title}</h2>
        </div>
        <div className="row">
          {props.data.data
            ? props.data.data.map((d, i) => (
                <div key={`${d.name}-${i}-1`} className='col-lg-4 col-md-12 col-sm-12 col-xs-12 card artist-card'>
                      <img src={d.img} className="artists-image" alt={d.name} />{' '}
                    <div className='artists-meta'><h4 className=" bold-text">{d.name}</h4></div>
                    <div className='artists-content'>
                      <p>{d.description}</p>
                      {d.link !== "" ? <a href={d.link} target="_blank" rel="noreferrer" className="link-dark">[Page]</a> : ""}
                    </div>
                  </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
