export const Events = (props) => {
  return (
    <div id={props.data.id} className='events text-center'>
      <div className='container-fluid'>
        <div className='justify-content-center'>
          <h2>{props.data.title}</h2>
          {props.data
            ? props.data.data.map((d, i) => (
              <div className="col-sm-auto col-md-4 event-card " key={'d' - i}>
                <img className={d.class} src={d.img} alt={d.title} />
                <div className="card-body">
                  <h5 className="event-title card-title">{d.title}</h5>
                  <h5 className="card-text">{d.price}</h5>
                  <p className="card-text">{d.date}</p>
                  <p className="card-text"><small className="text-muted">{d.location}</small></p>
                  <a href={d.link} target="_blank" rel="noreferrer" className="btn btn-primary">{d.ticket}</a>
                </div>
              </div>
            ))
            : 'loading'}
            {/* <p>To be announced soon.</p> */}
        </div>
      </div>
    </div>
  )
}